<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          left
          small
        >
          fas fa-key
        </v-icon>
        Alterar Senha
      </v-btn>
    </template>

    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-card>
        <v-card-title class="teal lighten-2 white--text">
          Alteração de Senha
        </v-card-title>

        <v-card-text class="pt-5 pb-0">
          <validation-provider
            v-slot="{ errors }"
            name="Senha Atual"
            rules="required|min:6"
          >
            <v-text-field
              v-model="oldPassword"
              label="Senha Atual"
              name="Senha Atual"
              outlined
              type="password"
              prepend-inner-icon="fas fa-key"
              :error-messages="errors"
              required
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Nova Senha"
            rules="required|min:6"
            vid="password"
          >
            <v-text-field
              v-model="password"
              label="Nova Senha"
              name="Nova Senha"
              outlined
              type="password"
              prepend-inner-icon="fas fa-keyboard"
              :error-messages="errors"
              required
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Confirmação de Nova Senha"
            rules="required|confirmed:password"
          >
            <v-text-field
              v-model="passwordConfirm"
              label="Confirmar Nova Senha"
              name="Confirmação de Nova Senha"
              outlined
              type="password"
              prepend-inner-icon="fas fa-shield-alt"
              :error-messages="errors"
              required
            />
          </validation-provider>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="light"
            @click="dialog = false"
          >
            <v-icon left>
              fas fa-times
            </v-icon>
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="invalid"
            @click="update"
          >
            <v-icon left>
              fas fa-save
            </v-icon>
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
        oldPassword: '',
        password: '',
        passwordConfirm: '',
      }
    },
    methods: {
      update () {
        this.$swal({
          title: 'Aterar senha?',
          text: 'Deseja realmente fazer a alteração de sua senha?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then(result => {
          if (result.value) {
            const headers = { headers: { authorization: 'Bearer ' + this.$store.getters.GET_TOKEN } }
            const uri = this.$urlAPI + 'auth/user/password/update/' + this.$store.getters.GET_ID_USER

            this.$swal({
              title: 'Atualizando senha!',
              html: 'Aguarde...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              onBeforeOpen: () => {
                this.$swal.showLoading()
              },
            })

            this.$http.put(uri, {
              oldPassword: this.oldPassword,
              password: this.password,
              password_confirmation: this.passwordConfirm,
            }, headers)
              .then(async response => {
                if (response.data.status) {
                  /* dados salvos com sucesso */
                  this.dialog = false

                  await localStorage.setItem('user', JSON.stringify(response.data.usuario))
                  await this.$store.commit('SET_USER', JSON.parse(localStorage.getItem('user')))

                  this.$swal({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Sua senha foi alterada!',
                  })
                } else {
                  /* erros de validação */
                  let error = ''
                  for (const erros of Object.values(response.data.erros)) {
                    error += erros + '\n'
                  }
                  this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo deu errado! ' + error,
                  })
                }
              })
              .catch(e => {
                this.$swal({
                  icon: 'error',
                  title: 'Erro!',
                  text: e,
                })
              })
          }
        })
      },
    },
  }
</script>
