var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" fas fa-key ")]),_vm._v(" Alterar Senha ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',{staticClass:"teal lighten-2 white--text"},[_vm._v(" Alteração de Senha ")]),_c('v-card-text',{staticClass:"pt-5 pb-0"},[_c('validation-provider',{attrs:{"name":"Senha Atual","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Senha Atual","name":"Senha Atual","outlined":"","type":"password","prepend-inner-icon":"fas fa-key","error-messages":errors,"required":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Nova Senha","rules":"required|min:6","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nova Senha","name":"Nova Senha","outlined":"","type":"password","prepend-inner-icon":"fas fa-keyboard","error-messages":errors,"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Confirmação de Nova Senha","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirmar Nova Senha","name":"Confirmação de Nova Senha","outlined":"","type":"password","prepend-inner-icon":"fas fa-shield-alt","error-messages":errors,"required":""},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"light"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-times ")]),_vm._v(" Fechar ")],1),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.update}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-save ")]),_vm._v(" Alterar ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }